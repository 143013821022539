import React from 'react'
import PropTypes from 'prop-types'
import AccordionPanel from '../AccordionPanel'

const Component = ({ image, toggled, color }) => (
  <AccordionPanel
    toggled={toggled}
    color={color}
    image={image}
    title="Life Cover"
  >
    <p>
      The amount of cover you select will be paid as a lump sum to your
      employees’ beneficiaries if they die.
    </p>
    <p>
      You can select the same amount of coverage for everyone (anywhere between
      R50k and R2 million), or you can set the cover as a multiple of each
      employee&rsquo;s annual salary (anywhere between 1X and 5X annual salary).
    </p>
    <p>
      Beneficiaries will also receive an additional R500 cash per month for 6
      months to help cover the cost of groceries.
    </p>
    <p>
      If they are diagnosed with a terminal illness and are expected to die
      within 12 months, they can choose to have up to 100% of their lump sum
      paid in advance to them or their beneficiary. If they opt for a 100% pay
      out, their policy will be terminated, even if they recover from the
      illness.
    </p>
  </AccordionPanel>
)

export default Component

Component.defaultProps = {
  image: undefined,
  color: 'transparent',
}

Component.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  toggled: PropTypes.func.isRequired,
}
