import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Hero from '../components/Home/hero'
import Features from '../components/Home/features'
import Products from '../components/Home/products'
import Process from '../components/Home/process'
import Background from '../components/Background'
import { METADATA } from '../config/home'
import { GROUP_URL_BASE } from '../../env'
import { LIGHTEST, PRIMARY_THIRD } from '../constants/colors'
import { TABLET } from '../constants/breakpoints'

const More = styled.div`
  height: 0;
  background: ${PRIMARY_THIRD};
  position: relative;
  box-sizing: border-box;

  &::after {
    box-sizing: border-box;
    content: '';
    position: absolute;
    top: 100%;
    width: 3rem;
    height: 3rem;
    left: 50%;
    background: inherit;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: 2;
  }
`

const BigContainer = styled.div`
  display: block;

  @media screen and (max-width: ${TABLET}px) {
    display: none;
  }
`

const SmallContainer = styled.div`
  display: none;

  @media screen and (max-width: ${TABLET}px) {
    display: block;
  }
`

const Page = () => (
  <Layout {...METADATA}>
    <Hero groupUrl={GROUP_URL_BASE} />
    <Features />
    <More />
    <BigContainer>
      <Background
        filename="pattern.png"
        color={LIGHTEST}
        size="cover"
        width="100%"
        position="-5rem"
      >
        <Products />
        <Process />
      </Background>
    </BigContainer>
    <SmallContainer>
      <Background
        filename="pattern.png"
        color={LIGHTEST}
        size="160%"
        repeat="repeat"
      >
        <Products />
        <Process />
      </Background>
    </SmallContainer>
  </Layout>
)

export default Page
