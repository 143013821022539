import React from 'react'
import styled from 'styled-components'
import {
  PHONE,
  TABLET,
  TWO_THIRDS_OF_DESKTOP,
  MAX_HEIGHT_TO_PULL_FEATURES_CLOSER_TO_QQ_FROM,
} from '../../constants/breakpoints'
import {
  DARKEST,
  LIGHTEST,
  PRIMARY_THIRD,
  SECONDARY,
} from '../../constants/colors'

const Container = styled.section`
  background-color: ${PRIMARY_THIRD};
  padding-top: 2.5rem;
  font-size: 130% !important;

  li {
    color: ${LIGHTEST} !important;
    line-height: 230% !important;
  }

  @media screen and (max-width: ${PHONE}px) {
    margin-top: 0;
    padding: 3rem 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 100% !important;

    li {
      line-height: 200% !important;
    }
  }

  /*
    Maximum effort to keep the heading above the fold
    on non-phone & tablet viewports
  */
  @media screen and (min-width: ${TABLET}px) and (max-height: ${MAX_HEIGHT_TO_PULL_FEATURES_CLOSER_TO_QQ_FROM}px) {
    padding: 0.25rem;
  }
`

const Content = styled.div`
  max-width: ${TWO_THIRDS_OF_DESKTOP}px;
  margin: 0 auto;
  padding: 2rem 1.5rem;

  li {
    line-height: 2.5rem;

    @media screen and (max-width: ${TABLET}px) {
      line-height: 2rem;
    }
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    max-width: 50%;

    @media screen and (max-width: ${PHONE}px) {
      max-width: 100%;
    }
  }

  @media screen and (max-width: ${PHONE}px) {
    display: block;
  }
`

const Column = styled.div`
  padding: 1.5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: ${TABLET}px) {
    padding: 0;

    &:last-child {
      margin: 0;
    }

    ul {
      margin: 0;
      margin-left: 1rem;
    }
  }
`

const Heading = styled.h1`
  text-align: center;
  color: ${LIGHTEST};
  text-transform: uppercase;
  margin-bottom: 0;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.5rem !important;
  }
`

const SubHeading = styled.h2`
  text-align: center;
  margin-top: 0.25rem;
  line-height: 150%;

  span {
    background-color: ${SECONDARY};
    color: ${DARKEST};
    padding: 0.2rem 0.5rem;
  }

  @media screen and (max-width: ${TABLET}px) {
    margin-top: 0.25rem;
    font-size: 1.15rem !important;
  }
`

const Component = () => (
  <Container>
    <Content>
      <Heading>RMA Group Life Cover</Heading>
      <SubHeading>
        <span>Add more value to your employee benefits</span>
      </SubHeading>
      <Row>
        <Column>
          <ul>
            <li>Simple, quick and easy to buy</li>
            <li>Up to R2m cover for each employee</li>
            <li>R500k guaranteed cover for 5+ employees</li>
          </ul>
        </Column>
        <Column>
          <ul>
            <li>3 simple medical questions for extra cover</li>
            <li>Cover starts immediately</li>
            <li>Legally employed foreigners welcome</li>
            <li>Total premiums from R299 p.m.</li>
          </ul>
        </Column>
      </Row>
    </Content>
  </Container>
)

export default Component
