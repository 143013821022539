import React from 'react'
import PropTypes from 'prop-types'
import AccordionPanel from '../AccordionPanel'

const Component = ({ image, toggled, color }) => (
  <AccordionPanel
    toggled={toggled}
    color={color}
    image={image}
    title="Occupational Disability Cover"
  >
    <p>
      The amount of cover you select (up to the maximum of R2 million for Group
      Cover) is paid if the insured person is permanently occupationally
      disabled.
    </p>

    <p>
      This means they suffer from a bodily injury, illness, disease or surgical
      operation which results in them being, in our opinion, totally and
      permanently unable to perform their current occupation’s duties, or the
      duties of another suitable occupation.
    </p>

    <p>
      Determining what a suitable alternative occupation will take into account
      the insured person’s education, previous training, abilities and work
      experience, and need not be with the existing employer. There is a 6 month
      deferred period before your employee is eligible for this benefit.
    </p>
  </AccordionPanel>
)

export default Component

Component.defaultProps = {
  image: undefined,
  color: 'transparent',
}

Component.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  toggled: PropTypes.func.isRequired,
}
