import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Background from '../Background'
import QuickQuoteForm from '../Form/qq'
import { TABLET } from '../../constants/breakpoints'
import { LIGHTEST, PRIMARY_THIRD } from '../../constants/colors'

const Container = styled.div``

const LargeContainer = styled.div`
  display: block;
  text-align: right;

  @media screen and (max-width: ${TABLET}px) {
    display: none;
  }
`

const SmallContainer = styled.div`
  display: none;

  @media screen and (max-width: ${TABLET}px) {
    display: block;
  }
`

const Component = ({ groupUrl }) => (
  <Container>
    <LargeContainer>
      <Background
        filename="hero-blacksmith.jpg"
        size="110%"
        width="100%"
        color={PRIMARY_THIRD}
      >
        <QuickQuoteForm
          groupUrl={groupUrl}
          type="hero"
          heading={{
            top: 'Get a quick quote for',
            bottom: 'RMA Group Life Cover',
          }}
        />
      </Background>
    </LargeContainer>
    <SmallContainer>
      <Background filename="pattern.png" size="cover" color={LIGHTEST}>
        <QuickQuoteForm
          heading={{
            top: 'Get a quick quote for',
            bottom: 'RMA Group Life Cover',
          }}
          type="hero"
        ></QuickQuoteForm>
      </Background>
    </SmallContainer>
  </Container>
)

export default Component

Component.propTypes = {
  groupUrl: PropTypes.string.isRequired,
}
