export const METADATA = {
  route: '/',
  imagePath: 'social-home.jpg',
  pageType: 'website',
  title: 'Home',
  description: `
    Add more value to your employee benefits with RMA Group Life Cover.
    It's simple, quick and easy to buy and cover starts immediately.
  `,
}
