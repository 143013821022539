import React from 'react'
import styled from 'styled-components'
import Step from './step'
import Anchor from '../Anchor'
import { DESKTOP, TABLET } from '../../constants/breakpoints'
import { GROUP_URL_BASE } from '../../../env'
import { PRIMARY, SECONDARY, TERTIARY, DARKER } from '../../constants/colors'

const Container = styled.section`
  padding: 1.5rem 2rem;
  padding-top: 0;
`

const Steps = styled.div`
  display: flex;
  max-width: ${DESKTOP}px;
  margin: 0 auto;

  strong {
    font-weight: bold;
  }

  sup,
  small {
    font-size: 0.8rem;
  }

  ul,
  ol {
    margin: 0;
    margin-bottom: 1rem;
  }

  li {
    padding-left: 0.5rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: ${TABLET}px) {
    display: block;

    .step {
      margin-top: 3rem;
    }

    .step:first-child {
      margin: 0;
    }
  }
`

const Heading = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.75rem !important;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.5rem !important;
  }
`

const SubHeading = styled.h2`
  font-size: 1.25rem !important;
  text-align: center;
  color: ${DARKER};
  margin-top: 0;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
`

const Component = () => (
  <Container>
    <Heading>Get started today</Heading>
    <SubHeading>with RMA Group Life Cover</SubHeading>
    <Steps>
      <Step number="1" title="Get your quote" color={TERTIARY}>
        <p>To get your quote, you will need the following employee details:</p>
        <ul>
          <li>First name</li>
          <li>Gender</li>
          <li>Age or date of birth</li>
          <li>Monthly income before tax</li>
        </ul>
        <p>
          You can add your employees one at a time or upload your own
          spreadsheet (eg. an export from your payroll system).
        </p>
        <p>
          There is also an{' '}
          <Anchor href={`${GROUP_URL_BASE}/excel-template`}>
            Excel spreadsheet
          </Anchor>{' '}
          template we created to help you get started.
        </p>
      </Step>

      <Step number="2" title="Choose the products" color={SECONDARY}>
        <p>
          You can choose to get cover by <strong>multiple of salary</strong> or
          an <strong>equal amount per person</strong>.
        </p>
        <p>You can then choose a combination of:</p>
        <ul>
          <li>Life cover</li>
          <li>Disability cover</li>
          <li>Family funeral cover</li>
        </ul>
        <p>
          Select the cover amounts you want to provide. The premium will change
          as you adjust your selections.
        </p>
      </Step>

      <Step number="3" title="Get your policy" color={PRIMARY}>
        <p>
          Once you’re happy and want to take out the cover, simply provide the
          remaining employee details:
        </p>
        <ul>
          <li>Surname</li>
          <li>Cellphone number</li>
          <li>Employment start date</li>
          <li>
            ID/Passport number<sup>*</sup>
          </li>
          <li>Email address (optional)</li>
        </ul>
        <p>Company details are needed and they include:</p>
        <ul>
          <li>Company name</li>
          <li>Registration number</li>
          <li>Contact details</li>
        </ul>
        <p>
          <sup>*</sup>
          <small>Passport expiry date and nationality required</small>
        </p>
      </Step>
    </Steps>
  </Container>
)

export default Component
