import React from 'react'
import PropTypes from 'prop-types'
import AccordionPanel from '../AccordionPanel'

const Component = ({ image, toggled, color }) => (
  <AccordionPanel
    toggled={toggled}
    color={color}
    image={image}
    title="Funeral Cover"
  >
    <p>
      The amount of cover you select (between R5,000 and R50,000) will be paid
      if your employee or any of their family members covered under the policy
      die. You can cover them, their spouse, and any number of children under
      the age of 21. You can also add new children later (any number of children
      under the age of 21).
    </p>

    <p>The benefits are defined as follows:</p>

    <ul>
      <li>Main member or spouse 100% of cover</li>
      <li>Child aged 14-21 100% of cover</li>
      <li>Child aged 6-14 50% of cover</li>
      <li>Child aged 0-6 50% of cover (maximum R10,000)</li>
    </ul>

    <p>
      The benefit will be paid out within 24 hours of receiving all the claim
      information we need. The policy does not terminate on the death of your
      employee’s spouse or children. If your employee dies, their spouse and
      children will continue to be covered for a further 6 months at no cost.
    </p>

    <p>
      Only family members who are resident in South Africa can be covered under
      our Family Funeral Plan, and children are only covered until they turn 21.
    </p>
  </AccordionPanel>
)

export default Component

Component.defaultProps = {
  image: undefined,
  color: 'transparent',
}

Component.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  toggled: PropTypes.func.isRequired,
}
