import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Heading from '../Heading'
import AstroForm from './astro'
import { QuickQuoteGroup } from '@simply-fin-services/astronomix'
import { BORDER_RADIUS } from './index'
import { LIGHTEST } from '../../constants/colors'
import {
  DESKTOP,
  PHONE,
  TABLET,
  ABOVE_THE_FOLD_MAX_HEIGHT,
} from '../../constants/breakpoints'

/* A container strip from far left to far right */
const Strip = styled.div`
  max-width: ${DESKTOP}px;
  padding-top: 4rem;
  margin: 0 auto;

  /*
    Maximum effort to keep the heading above the fold
    on non-phone & tablet viewports
  */
  @media screen and (min-width: ${TABLET}px) and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
    padding: 1rem 0.25rem;
  }

  @media screen and (min-width: ${TABLET}px) and (max-width: ${DESKTOP}px) {
    /* Align form with the header. */
    padding-right: 2rem;
  }

  @media screen and (max-width: ${TABLET}px) {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  }
`

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-right: 0;
  border-radius: ${BORDER_RADIUS}px;
  background-color: ${LIGHTEST}66;
  padding: 1rem;
  margin-top: 0;

  /*
    Maximum effort to keep the heading above the fold
    on non-phone & tablet viewports
  */
  @media screen and (min-width: ${TABLET}px) and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
    padding: 0;
  }

  @media screen and (max-width: ${TABLET}px) {
    background-color: transparent;
    margin: 0 auto;
    width: 100%;
    padding: 0 1rem;
  }
`

const Wrapper = styled.div`
  border-radius: ${BORDER_RADIUS}px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: ${LIGHTEST}cc;

  @media screen and (min-width: ${PHONE}px) and (max-width: ${TABLET}px) {
    background-color: ${LIGHTEST}66;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${PHONE}px) {
    background-color: transparent;
    box-shadow: none;
  }
`

const HeadingContainer = styled.div`
  padding-bottom: 1.5rem;
  display: block;

  /*
    Maximum effort to keep the heading above the fold
    on non-phone & tablet viewports
  */
  @media screen and (min-width: ${TABLET}px) and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
    height: 0;
    overflow: hidden;
  }

  @media screen and (max-width: ${TABLET}px) {
    display: block;
    margin-top: 0;
    padding-top: 2rem;

    h1,
    h2 {
      text-align: center;
    }
  }

  @media screen and (max-width: ${PHONE}px) {
    padding-top: 0;
  }
`

const hasHeading = (heading) => heading.top || heading.bottom

const QuickQuoteForm = ({ groupUrl, heading, type }) => (
  <Strip type={type} visible={hasHeading(heading)}>
    <Container type={type}>
      <Wrapper type={type}>
        <HeadingContainer type={type} visible={hasHeading(heading)}>
          <Heading top={heading.top} bottom={heading.bottom} />
        </HeadingContainer>
        <AstroForm pad={false} wrapLabels={true}>
          <QuickQuoteGroup
            phoneNumberRequired={true}
            sender_reference="rml"
            group_url={groupUrl}
          />
        </AstroForm>
      </Wrapper>
    </Container>
  </Strip>
)

QuickQuoteForm.defaultProps = {
  heading: {},
}

QuickQuoteForm.propTypes = {
  groupUrl: PropTypes.string.isRequired,
  heading: PropTypes.shape({
    bottom: PropTypes.string,
    top: PropTypes.string,
  }),
  type: PropTypes.oneOf(['hero', 'inline']).isRequired,
}

export default QuickQuoteForm

// import React from 'react'
// import styled from 'styled-components'
// import PropTypes from 'prop-types'
// import Heading from '../Heading'
// import { LIGHTEST, NEUTRAL } from '../../constants/colors'
// import {
//   DESKTOP,
//   PHONE,
//   TABLET,
//   ABOVE_THE_FOLD_MAX_HEIGHT,
// } from '../../constants/breakpoints'

// const BORDER_RADIUS = 10

// // set the styles for hero, inline or def (default) form types
// const getStyleByType = (type, values) => {
//   return values[type] || 'auto'
// }

// const Strip = styled.div`
//   width: 100%;
//   margin: 0 auto;
//   padding-top: ${(props) =>
//     getStyleByType(props.type, {
//       hero: props.form === 'qq' ? '5rem' : '0',
//       inline: '1.5rem',
//     })};
//   max-width: ${(props) =>
//     getStyleByType(props.type, {
//       hero: `${DESKTOP}px`,
//       def: '100%',
//     })};

//   /* Align form with the header. */
//   @media screen and (min-width: ${TABLET}px) and (max-width: ${DESKTOP}px) {
//     padding-right: ${(props) =>
//       getStyleByType(props.type, {
//         hero: '2rem',
//         inline: '0',
//       })};
//   }

//   /* Form and heading on qq on the home page must be above the fold */
//   @media screen and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
//     padding-top: ${(props) =>
//       getStyleByType(props.type, {
//         hero: props.form === 'qq' ? '1.25rem' : 'auto',
//         inline: '0',
//       })};
//   }
// `

// const Container = styled.div`
//   max-width: 500px;
//   margin: auto;
//   margin-right: 0;
//   border-radius: ${BORDER_RADIUS}px;

//   background-color: ${(props) =>
//     getStyleByType(props.type, {
//       hero: `${LIGHTEST}33`,
//       inline: 'transparent',
//     })};

//   padding: ${(props) =>
//     getStyleByType(props.type, {
//       hero: `1rem`,
//       inline: '0',
//     })};

//   @media screen and (max-width: ${TABLET}px) {
//     background-color: transparent;
//     max-width: ${(props) => (props.form === 'qq' ? `${TABLET}px` : '500px')};
//     margin: 0 auto;
//     width: 100%;
//   }

//   /* Form and heading on qq on the home page must be above the fold */
//   @media screen and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
//     padding: ${(props) =>
//       getStyleByType(props.type, {
//         hero: props.form === 'qq' ? '0' : '10rem',
//         inline: '0',
//       })};
//   }
// `

// const Wrapper = styled.div`
//   border-radius: ${BORDER_RADIUS}px;
//   box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%),
//     0 12.5px 10px rgb(0 0 0 / 4%), 0 22.3px 17.9px rgb(0 0 0 / 4%),
//     0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);

//   background-color: ${(props) =>
//     getStyleByType(props.type, {
//       hero: `${LIGHTEST}cc`,
//       def: 'transparent',
//     })};

//   @media screen and (min-width: ${PHONE}px) and (max-width: ${TABLET}px) {
//     background-color: transparent;
//   }

//   @media screen and (max-width: ${PHONE}px) {
//     background-color: transparent;
//     box-shadow: none;
//   }
// `

// const HeadingContainer = styled.div`
//   display: ${(props) =>
//     getStyleByType(props.type, {
//       hero: 'block',
//       def: 'none',
//     })};

//   /* Try keep the home page heading above the fold. */
//   @media screen and (min-width: ${TABLET}px) and (max-height: ${ABOVE_THE_FOLD_MAX_HEIGHT}px) {
//     display: ${(props) => (props.form === 'qq' ? 'none' : 'block')};
//   }

//   @media screen and (max-width: ${TABLET}px) {
//     display: block;
//     margin-top: 1rem;
//     margin-bottom: 1.5rem;

//     h1,
//     h2 {
//       text-align: center;
//     }
//   }
// `

// const AstroOverrides = styled.div`
//   > div {
//     background-color: transparent;
//     width: 100%;
//     max-width: 100%;
//     padding-left: 1.5rem;
//     padding-right: 1.5rem;
//     padding-bottom: ${(props) => (props.form === 'lead' ? '2rem' : '0')};

//     @media screen and (max-width: ${PHONE}px) {
//       padding: 0;
//     }
//   }

//   label,
//   span {
//     font-weight: normal !important;
//     white-space: ${(props) => (props.form === 'lead' ? 'nowrap' : 'wrap')};
//   }

//   /* ----------------- astro overrides ----------------- */

//   /* Placeholders */
//   input::placeholder,
//   div[class$='placeholder'] {
//     font-weight: 100;
//     color: #aaa;
//   }

//   /* Quick Quote button options (male, female and evenly split) */
//   button[data-color='neutral'] {
//     background-color: ${LIGHTEST};
//     font-weight: normal;
//     border: solid 1px ${NEUTRAL};
//     color: #aaa;
//     display: inline-block;
//     margin: 0.25rem 0;
//   }

//   /* Submit button */
//   button[disabled],
//   button[disabled]:hover {
//     color: #505050;
//     background-color: #c9c9c9;
//     border: solid 1px #a9a9a9;
//   }
// `

// const Form = ({ heading, form, type, children }) => (
//   <Strip type={type} form={form}>
//     <Container type={type} form={form}>
//       <Wrapper type={type} form={form}>
//         <HeadingContainer type={type} form={form}>
//           <Heading top={heading.top} bottom={heading.bottom} />
//         </HeadingContainer>
//         <AstroOverrides type={type} form={form}>
//           {children}
//         </AstroOverrides>
//       </Wrapper>
//     </Container>
//   </Strip>
// )

// Form.defaultProps = {
//   heading: {},
// }

// Form.propTypes = {
//   children: PropTypes.node.isRequired,
//   form: PropTypes.oneOf(['qq', 'lead']).isRequired,
//   heading: PropTypes.shape({
//     bottom: PropTypes.string,
//     top: PropTypes.string,
//   }),
//   type: PropTypes.oneOf(['hero', 'inline']).isRequired,
// }

// export default Form
