import React, { useState } from 'react'
import styled from 'styled-components'
import Background from '../Background'
import Accordion from '../Accordion'
import Life from './life'
import Disability from './disability'
import Funeral from './funeral'
import { DARKER, LIGHTEST } from '../../constants/colors'
import {
  DESKTOP,
  TABLET,
  TWO_THIRDS_OF_DESKTOP,
} from '../../constants/breakpoints'

const Container = styled.section`
  margin-bottom: 2.5rem;
  position: relative;
  padding-top: 2rem;

  @media screen and (max-width: ${DESKTOP}px) {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
  }
`

const Description = styled.div`
  text-align: justify;
  max-width: ${TWO_THIRDS_OF_DESKTOP}px;
  padding-top: 3rem;
  margin: 0 auto;
`

const Heading = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.75rem !important;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.5rem !important;
  }
`

const SubHeading = styled.h2`
  font-size: 1.25rem !important;
  text-align: center;
  color: ${DARKER};
  margin-top: 0;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
`

const Products = styled.div`
  position: relative;
  padding-top: 2.5rem;
  z-index: 1;
  margin: 0 auto;
  max-width: ${TWO_THIRDS_OF_DESKTOP}px;

  @media screen and (max-width: ${TABLET}px) {
    padding: 0;
  }
`

const Component = () => {
  const [life, toggleLife] = useState(false)
  const [disability, toggleDisability] = useState(false)
  const [funeral, toggleFuneral] = useState(false)

  return (
    <Container>
      <Background filename="pattern.png" color={LIGHTEST} />
      <Description>
        <Heading>What you can give</Heading>
        <SubHeading>to your employees</SubHeading>
        <p>
          People who work in our businesses are an important part of our daily
          lives. They are often family breadwinners and if tragedy strikes,
          their dependents may be left destitute. As their employer, you may be
          the one left with a moral responsibility towards their family if
          something happens to them. RMA Group Life Cover includes any
          combination of the following to suit the needs of your employees and
          your budget.
        </p>
      </Description>
      <Products>
        <Accordion>
          <Life
            image="img-life.jpg"
            toggled={() => {
              toggleLife(!life)
              return life
            }}
          />
          <Disability
            toggled={() => {
              toggleDisability(!disability)
              return disability
            }}
          />
          <Funeral
            toggled={() => {
              toggleFuneral(!funeral)
              return funeral
            }}
          />
        </Accordion>
      </Products>
    </Container>
  )
}

export default Component
